import React from 'react'

const Testimonial = () => {
    return (
        <div className='testimonial'>
      
        </div>
    );
};

export default Testimonial;
