import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const About = () => {
    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "abouts"]';

        client.fetch(query)
            .then((data) => {setAbouts(data)})
    }, []);
    return (
        <>
        <div className="wrapper">
            <motion.div
            whileInView={{ opacity: 1 }}
            whileHover= {{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            >
                <img src={images.aboutmeimg} alt="profile_bg" />
                <p className='head-text'>Welcome ! My name is Eugene, <br /> I Have proficiency in a variety of backend technologies, including <span>Python, Flask, Nodejs, Express, PostgreSQL, and MongoDB</span>, with the experience of integrating <span>REST APIs, manage databases, and handle server-side code.</span> I also have experience in front-end development, using technologies such as <span>React, HTML, CSS/SCSS, Bootstrap, and  jQuery</span> to build responsive and appealing user interfaces.
                </p>
                <p className='head-text'>I am a recent bootcamp graduate from <span>the University Of South Florida and a Full Stack web developer</span>  with a strong focus on backend development. I have a passion for problem solving with a deep understanding of data structures and algorithms. <span>I excel at designing and building scalable, efficient, and secure server-side applications.</span> 
                </p>
                <p className='head-text'>If you are looking for a <span>passionate problem solver and dedicated software developer</span>  who can bring value to your team and be apart of your success, I would be more then happy to discuss potential opportunities with you.</p> 
            </motion.div>            
        </div>
        <div className='app__profiles'>
            {abouts.map((about, index) => (
                <motion.div
                    whileInView={{ opacity: 1 }}
                    whileHover= {{ scale: 1.1 }}
                    transition={{ duration: 0.5, type: 'tween' }}
                    className="app__profile-item"
                    key={about.title + index}
                >
                    <img src={urlFor(about.imgUrl)} alt={about.title} />
                    <p className='p-title bold-text' style={{ marginTop: 20 }}>{about.title }</p>
                    <p className='bold-text' style={{marginTop: 20}}>{about.description}</p>
                </motion.div>
            ))}
        </div>
        </>
    );
};

export default AppWrap(
    MotionWrap(About, 'app__about'),
    'about',
    "app__whitebg"
);