import React from "react";
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import './SocialMedia.scss'  

const SocialMedia = () => {
    return (
        <div className="app__social">
            <div>
                <a href="https://github.com/GeneTheDev"><BsGithub /></a>                
            </div>
            <div>
                <a href="https://www.linkedin.com/in/gene-the-developer98/"><BsLinkedin /></a>
            </div>
        </div>
    );
};

export default SocialMedia;